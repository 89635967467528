export default {
  methods: {
    hashSprites(arg){
        // console.log(arg)
        return new Promise((resolve,reject)=> {
          // console.log(arg.length)
          for(let i= 0; i< arg.length; i++){
            this.hashHexKeys(arg[i].frames)
          }
          resolve(arg)
        })
    },
    hashHexKeys(frames){
      for(let i=0; i<frames.length; i++){
      //console.log(sprite.frames)
        if( Array.isArray(frames[i]) ){

          for(let j=0; j<frames[i].length; j++){ // layers

            let targetObj = {}
            let key1 = Object.keys(frames[i][j])
            for(let k=0; k< key1.length; k++){ // keys

              let key2 = Object.keys(frames[i][j])[k]


              Object.assign(targetObj, { ["#"+ key2 ]: frames[i][j][ key2 ] } )

              if(k == key1.length-1){
                frames[i][j] = {}
                Object.assign(frames[i][j], targetObj )
              }
            }
          }
        }
        else{
          let targetObj = {}
          let key1 = Object.keys(frames[i])
          for(let j=0; j< key1.length; j++){ // keys

            let key2 = key1[j]

            Object.assign(targetObj, { ["#"+ key2 ]: frames[i][ key2 ] } )

            if(j == key1.length-1){
              frames[i] = {}
              Object.assign(frames[i], targetObj )
            }
          }
        }
      }
      return frames
    },
    unhashHexKeys(frames){
    for(let i=0; i<frames.length; i++){
    //console.log(sprite.frames)
      if( Array.isArray(frames[i]) ){

        for(let j=0; j<frames[i].length; j++){ // layers

          let targetObj = {}
          let key1 = Object.keys(frames[i][j])
          for(let k=0; k< key1.length; k++){ // keys

            let key2 = Object.keys(frames[i][j])[k]


            Object.assign(targetObj, { [ key2.slice(1) ]: frames[i][j][ key2 ] } )

            if(k == key1.length-1){
              frames[i][j] = {}
              Object.assign(frames[i][j], targetObj )
            }
          }
        }
      }
      else {
        let targetObj = {}
        let key1 = Object.keys(frames[i])
        for(let j=0; j< key1.length; j++){ // keys

          let key2 = Object.keys(frames[i])[j]

          Object.assign(targetObj, { [key2.slice(1) ]: frames[i][ key2 ] } )

          if(j == key1.length-1){
            frames[i] = {}
            Object.assign(frames[i], targetObj )
          }
        }
      }
    }
    return frames
  },
  }
}
