<template>
  <div class="sprites">
    <div v-if="$store.getters.galleryLoading" class="loader-container">
      <div class="loader-wrapper">
        <div class="loader-anim">
        </div>
      </div>
    </div>
    <div v-else class="container">

      <div class="sprite-gallery-grid">

        <router-link :to="{ name:'editor', params:{unique_key: sprite.unique_key} }"
        class="sprite-item"
        v-for="(sprite,spriteKey) in dbSprites"
        :key="sprite.name.replaceAll(' ', '_').replaceAll('-', '_') + '_' + sprite.unique_key"
        :value="sprite.name.replaceAll(' ','_').replaceAll('-', '_') +'-'+ sprite.unique_key"
        :style="'background-color:'+sprite.background"
        @click="setStoreSpriteIndex(spriteKey)"
        ref="previewSprite">

          <div class="sprite-item-container">

            <div class="sprite-item-preview-grid"
            :style="setGridDims(sprite, spriteKey)"
            ref="previewGrid">
              <div class="sprite-item-preview-pixel"
              v-for="(pixel,pixelKey) in sprite.size.cols * sprite.size.rows"
              :key="pixelKey" :value="sprite.name"
              ref="previewPixel"
              >
              </div>
            </div>

            <div class="sprite-item-info --bottom">
              <div class="sprite-item-info-container">
                <div class="info-split">
                  <p class="sprite-name">{{ sprite.name }}</p>
                </div>
                <div class="info-split">
                  <p>{{ sprite.frames.length == 1 ? "1 frame" : sprite.frames.length + " frames"}}</p>
                  <p>{{ sprite.unique_key }}</p>
                </div>
                <div class="info-split">
                  <p>{{ sprite.size.cols}} x {{ sprite.size.rows}}</p>
                  <p>{{ sprite.size.cols * sprite.size.rows}} pixels</p>
                  <p>{{ numOfColors(dbSprites[spriteKey]) }} colors</p>
                </div>
              </div>
            </div>
            <div class="sprite-item-info  --top --right" @click.stop.prevent="copyToClip(sprite.unique_key)">
              <div class="sprite-item-info-container">
                <p class="sprite-name flex fj-cent" style="font-size: 1rem; width: calc(var(--size-unit)*3)">
                  <i class="fa-solid fa-key"></i>
                </p>
              </div>
            </div>
            <div class="sprite-item-info  --top --right-2"
            v-if="$store.getters.AuthUser && $store.getters.AuthUser.id == sprite.author">
              <div class="sprite-item-info-container">
                <p class="sprite-name flex fj-cent" style="font-size: 1rem; width: calc(var(--size-unit)*3)">
                  <i class="fa-solid fa-eye-slash"></i>
                </p>
              </div>
            </div>
            <div class="sprite-item-info  --top --right-under"
            v-if="$store.getters.AuthUser && $store.getters.AuthUser.id == sprite.author">
              <div class="sprite-item-info-container">
                <p class="sprite-name flex fj-cent" style="font-size: 1rem; width: calc(var(--size-unit)*3)">
                  <i class="fa-solid fa-file-invoice-dollar"></i>
                </p>
              </div>
            </div>
            <div class="sprite-item-info  --top --left" @click.stop.prevent="addLike(sprite.unique_key)">
              <div class="sprite-item-info-container">
                <p class="sprite-like flex fj-cent" style="font-size: 1rem; width: calc(var(--size-unit)*3)">
                  <i class="fa-regular fa-heart"></i>
                </p>
              </div>
            </div>

          </div>

        </router-link>


    </div>
    
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue"
import {supabase} from "@/supabase.js"
import _hashHexKeys from '@/mixins/_hashHexKeys'
import clip from "copy-to-clipboard"
import _ from "lodash"

const gridThumbRatio = {
  w: 64,
  h: 64
}

export default {
  name: "Sprites",
  components: {
    pagination,
  },
  data(){
    return{
      dbSprites: [],
      isLoading: false,
    }
  },
  mixins: [
    _hashHexKeys,
  ],
  props:{
    pageNum: {
      type: Number,
      required: true
    }
  },
  computed: {
    RPP(){
      return this.$store.state.rpp
    },
    previewSpriteWidth() {
      if(this.$refs.previewSprite){
        return this.$refs.previewSprite.clientWidth
      }
    }
  },
  methods: {
    async addLike(unique_key){
      const { likesData, error } = await supabase
          .from('likes')
          .select()
          .eq('sprite_key', unique_key)
          
      if(likedData) console.log(likedData)
      

      if(likedData.liked_by.includes(this.$store.AuthUser.id)){
      }
      else {
        const { data, error } = await supabase
          .from('likes')
          .update({
            liked_by: [
              ...sprite.liked_by,
              this.$store.getters.AuthUser.id
            ]
          })
          .eq('like_key', unique_key)
        if(error){
          console.log(error)
        }
        else if (data) {
          this.$toast(
            `You liked ${sprite.unique_key}, '${sprite.name}' .`,
            {
              timeout: 6000,
              icon: 'fas fa-file-arrow-up',
            }
          )
        }
      }
    },
    // each sprite in the gallery has a different size when displayed
    // the grid dimensions (grid dims) has then to be set
    setGridDims (sprite, key){
      if(sprite.size.cols < sprite.size.rows){
        var res = `
          grid-template-columns: repeat(${sprite.size.cols}, 1fr);
          grid-template-rows: repeat(${sprite.size.rows}, 1fr);
          min-height: ${Math.round(240 * gridThumbRatio.h / 100)}px;
          min-width: ${Math.round(sprite.size.cols * (240 * gridThumbRatio.w / 100) / sprite.size.rows)}px;
          `
      }
      else if(sprite.size.cols >= sprite.size.rows){
        var res = `
          grid-template-columns: repeat(${sprite.size.cols}, 1fr);
          grid-template-rows: repeat(${sprite.size.rows}, 1fr);
          min-height: ${Math.round(sprite.size.rows * (280 * gridThumbRatio.h / 100) / sprite.size.cols)}px;
          min-width: ${Math.round(280 * gridThumbRatio.h / 100)}px;
          `
      }
      return res
    },
    // method to get the number of color of a sprite
    numOfColors(sprite){
      var arr= []
      for (let frame of sprite.frames){
        // console.log(frame)
        if(Array.isArray(frame)){
          for (let layer of frame) {
            // console.log(layer)
            for (let color of Object.keys(layer)){
              if(arr.indexOf(color)== -1 && Object.keys(layer).includes(color)){
                arr.push(color)
              }
            }
          }
        }
        else {
          for (let color of Object.keys(frame)){
            if(arr.indexOf(color)== -1 && Object.keys(frame).includes(color)){
              arr.push(color)
            }
          }
        }
      }
      return arr.length
    },
    setStoreSpriteIndex(index){
      this.$store.commit('SET_INDEX', index)
    },
    renderFrame(frame, spriteKey) {
      for (let color of Object.keys(frame)) {
        for (let pixelIndex of frame[color]) {
          this.applyColorToPixel(spriteKey, pixelIndex, [color])
        }
      }
    },
    createPreviewThumb(sprites) {
      for (let i = 0; i < sprites.length ; i++){
        if(Array.isArray(sprites[i].frames[0])){
          for (let layer of sprites[i].frames[0]) {
            this.renderFrame(layer, i)
          }
        }
        else {
          this.renderFrame(sprites[i].frames[0], i)
        }
      }
    },
    applyColorToPixel(spriteKey, pixelIndex, colorCode){
      this.$refs.previewSprite[spriteKey]._self.$options._renderChildren["0"].children["0"].children[pixelIndex].elm.style.backgroundColor = colorCode
    },
    createThumb(sprites){
      for (let h = 0; h < sprites.length ; h++){
        // for (let i=0; i<= 1; i++){
        for (let j=0; j < Object.keys(sprites[h].frames[0]).length; j++){
          // console.log(Object.keys(sprites[h].frames[0]).length)
          let numberOfPixelsInColor = Object.values(sprites[h].frames[0][Object.keys(sprites[h].frames[0])[j]])
          // console.log(numberOfPixelsInColor.length)
          for (let k=0; k< numberOfPixelsInColor.length; k++){ // number of pixel in color
            this.applyColorToPixel( h, numberOfPixelsInColor[k], [Object.keys(sprites[h].frames[0])[j]] )
          }
        }
      }
    },
    createPixelSizes(sprites) {
      for (let h = 0; h < sprites.length ; h++){
        var pixelS
        if(sprites[h].size.cols < sprites[h].size.rows) {
          pixelS = (this.$refs.previewSprite[h].$el.clientWidth * gridThumbRatio.w / 100) / sprites[h].size.cols
        }
        else if(sprites[h].size.cols >= sprites[h].size.rows){
          pixelS = (this.$refs.previewSprite[h].$el.clientHeight * gridThumbRatio.h / 100) / sprites[h].size.rows
        }
        this.setPixelSize(h, pixelS)
        // console.log(pixelS)
      }
    },
    setPixelSize(index, size) {
      this.$refs.previewSprite[index].style = `--preview-pixel-size : ${size}px ;`
    },
    
    async getDbSprites(){
      const { data, error } = await supabase
      .from('sprites')
      .select()
      .order('id', { ascending: false })
      .neq('public', false)
      .range((this.$route.params.pageNum-1) * (this.RPP), (this.$route.params.pageNum-1) * (this.RPP) + this.RPP-1 )
      return data
    },
    copyToClip(text){
      clip(text)

      this.$toast(
        `${text} copied to clipboard`,
          {
            timeout: 8000,
            icon: 'fas fa-circle-user',
          }
      )
    },
  },
  beforeMount(){
    // this.isLoading = true
    this.$store.commit('setGalleryLoading', true)
    this.getDbSprites()
    .then( (res) => {
      return new Promise((resolve,reject) => {
        res.forEach((sprite, index, array) => { 
          sprite.frames = JSON.parse(sprite.frames)
          sprite.size = JSON.parse(sprite.size)
        })
        resolve( res )
      })
    })
    .then( (res) => {

      return new Promise((resolve,reject) => {
        this.dbSprites = res
        resolve(this.dbSprites)
        this.$store.commit('setGallerySprites', this.dbSprites)
      })
    })
    .then( (res) => {
      this.$store.commit('setGalleryLoading', false)
    })
    .then(() => {
      this.createPreviewThumb(this.dbSprites)
      this.createPixelSizes(this.dbSprites)
    })
    .catch((e) => {console.log(e)})
    
  },
}
</script>

<style lang="css">
.sprites{
  position: relative;
  padding-bottom: var(--footer-h);
}
.sprites .container{
  width: calc(100%);
}
.loader-container{
  position: fixed;
  min-height: calc(100vh - var(--footer-h) - var(--nav-h));
  height: calc(100% - var(--footer-h) - var(--nav-h));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-wrapper{
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-40px, -60px);
}
.loader-anim {
  height: 40px;
  width: 40px;
  background-color: var(--color-accent);
  animation: square .64s ease infinite;
}
@keyframes square {
  0%{transform: translate(0,0)}

  25%{transform: translate(80px, 0)}

  50%{transform: translate(80px, 80px)}

  75%{transform: translate(0, 80px)}

  100%{transform: translate(0, 0)}
}
.sprite-gallery-grid{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 24px;
  width: 100%;
  margin: 0;
  padding: 40px;
}
@media screen and (max-width: 512px){
  .sprite-gallery-grid{
    padding: 24px;
  }
}
.sprite-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 200px;
  border-radius: 6px;
  transition: box-shadow var(--med-trans);
  overflow: hidden;
}
.sprite-item:hover {
  color: var(--over-bg);
  cursor: pointer;
  box-shadow: 0 0 0 4px var(--color-accent);
  transition: box-shadow var(--fast-trans), color var(--med-trans);
}
.sprite-item-info {
  background-color: rgba(0,0,0,0.6);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 24px);
  margin: 0 auto 12px;
  border-radius: 4px;
  font-size: 0.8rem;
}
.sprite-item-info.--bottom {
  transform: translateY(120px);
  transition: transform .24s ease;
}
.sprite-item-container:hover .sprite-item-info.--bottom {
  transform: translateY(0);
  transition: transform .24s ease;
}
.sprite-item .sprite-item-info.--top.--left{
  transform: translateX(-80px);
}
.sprite-item:hover .sprite-item-info.--top.--left{
  transform: translateX(0);
}
/* .sprite-item .sprite-item-info.--right{
  transform: translateX(80px);
  transition: transform .24s ease;
}
.sprite-item:hover .sprite-item-info.--right{
  transform: translateX(0);
  transition: transform .24s ease;
} */
.sprite-item .sprite-item-info.--top{
  opacity: 0;
  transform: scale(0);
  transition: opacity .24s ease, transform .24s ease;
}
.sprite-item:hover .sprite-item-info.--top{
  opacity: 1;
  transform: scale(1);
  transition: opacity .24s ease, transform .24s ease;
}
.sprite-item-info.--top{
  background-color: hsla(var(--spread), 0.65);
  position: absolute;
  top: 16px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  padding: 8px;
}
.sprite-item-info.--right{
  right: calc(var(--space-unit)*2);
}
.sprite-item-info.--right-2{
  right: calc(var(--space-unit)*8);
}
.sprite-item-info.--top.--right-under{
  right: calc(var(--space-unit)*2);
  top: calc(var(--space-unit)*8.4);
}
.sprite-item-info.--top:hover{
  background-color: var(--color-accent);
  color: var(--color-o-accent);
}
.sprite-item-info.--top:hover .sprite-name{
  color: var(--color-o-accent);
}
.sprite-item-info.--top .sprite-name{
  font-size: 0.8rem;
  font-weight: normal;
}

.sprite-item-info.--top .sprite-item-info-container{
  display: inline-block;
  width: auto;
}
.sprite-item-info.--left{
  left: calc(var(--space-unit)*2);
  padding: calc(var(--space-unit)*1);
}
.sprite-item-container{
  position: relative;
  width: calc(100%);
  height: 240px;
}
.sprite-item-info p {
  line-height: 1;
}
.sprite-item-info-container {
  width: calc(100% - 24px);
  margin: 0 auto;
}
.info-split{
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--panel-color);
}
.sprite-item-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.sprite-item-preview-grid {
  display: grid;
  user-select: none;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 120px;
  transform: translate(-50%, -50%);
}
.sprite-item-preview-pixel {
  width: var(--preview-pixel-size);
  height: var(--preview-pixel-size);
  user-select: none;
  pointer-events: none;
}
.sprite-name {
  color: var(--panel-hover);
  font-size: 1rem;
  font-weight: 600;
}
.sprite-like{
  width: calc(var(--size-unit)*3);
  /* height: calc(var(--size-unit)*3); */
}

</style>
